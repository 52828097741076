import React from 'react'
import piece from '../img/voiture.jpeg'
import {motion} from "framer-motion"
function Pieces() {
  return (
    <div className='grid md:grid-cols-2 gap-4 sm:mx-40 mx-4 p-3  sm:py-0 items-center sm:space-x-6 bg-white rounded-lg'>

        <motion.div 
         initial={{opacity:0, x:-100,}}
         transition={{duration:1.2,}}
         whileInView={{opacity:1, x:0, }}
        className=' flex flex-col'>
          <p className='text-blue-800 text-sm sm:text-xl font-bold mb-4'>Pièces détachées d'origine </p>
          <p className='text-black text-[12px] sm:text-lg text-justify'>
          <b className=' text-red-800 italic '>Best-Transport</b> vous apporte toutes les pièces d'origine de toutes les marques de véhicules. Nos pièces sont commandés depuis les fabriquants dans le monde entier. 
          </p>
          <p className='text-blue-800 text-xs sm:text-xl font-bold mb-4 cursor-pointer'>Voir +</p>
        </motion.div>

        <motion.img
         initial={{opacity:0, x:100,}}
         transition={{duration:1.2,}}
         whileInView={{opacity:1, x:0, }}
         src={piece} alt=""  className='rounded-md w-[200px] h-[188px]  sm:w-[400px] sm:h-[288px]  object-contain text-xl'/>

    </div>
  )
}

export default Pieces