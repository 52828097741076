import Layout from "./components/Layout";
import { Route, Routes } from "react-router-dom";
import Acceuil from "./pages/acceuil/Acceuil";
import Vehicule from "./pages/vehicules/Vehicule";
import Pieces from "./pages/pieces/Pieces";
import Etechnologie from "./pages/technologie/Etechnologie";
import Accessoires from "./pages/accessoires/Accessoires";
import MonContact from "./pages/contact/MonContact";

function App() {
	return (
		<div className="Bitter flex flex-col bg-gradient-to-b from-slate-50 to bg-slate-100 scrollbar-hide">
			<Layout>
				<Routes>
					<Route path="/" element={<Acceuil />} />
					<Route path="/vehicules" element={<Vehicule />} />
					<Route path="/piecesdetache" element={<Pieces />} />
					<Route path="/etechnologie" element={<Etechnologie />} />
					<Route path="/accessoires" element={<Accessoires />} />
					<Route path="/contact" element={<MonContact />} />
				</Routes>
			</Layout>
		</div>
	);
}

export default App;
