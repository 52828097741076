import React from 'react'
import Transport from '../../components/Transport'
import Pieces from '../../components/Pieces'
import Electro from '../../components/Electro'
import Hero from '../../components/Hero'
import Texte from '../../components/Texte'
import Ourcars from '../../components/Ourcars'

function Acceuil() {
  return (
      <div className='justify-center  '>
      <Hero/>
      
      <Transport />
      <Texte />
      <Ourcars />
      <Pieces />
      <Electro />

    </div>
  )
}

export default Acceuil