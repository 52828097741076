import React from "react";
import Contact from "./Contact";
import Topcontact from "./Topcontact";

function MonContact() {
	return (
		<div className="flex flex-col bg-emerald-950">
            <Topcontact />
			<Contact />
		</div>
	);
}

export default MonContact;
