import React from 'react'
import PieceDetaches from './PieceDetaches'
import MySliderAppPieces from '../../components/MySliderAppPieces'


function Pieces() {
  return (
    <div className='justify-center  '>
        <MySliderAppPieces />
        <PieceDetaches />
    </div>
  )
}

export default Pieces