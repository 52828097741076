import React from 'react'
import Vehicules from './Vehicules'
import MySliderAppVehicule from '../../components/MySliderAppVehicule'


function Vehicule() {
  return (
    <div>
        <MySliderAppVehicule />
        <Vehicules />
    </div>
  )
}

export default Vehicule