import React from 'react'
import car from '../img/card11.jpg'
import {motion} from "framer-motion"


function Electro() {

  

  return (
    <div className='grid md:grid-cols-2  gap-4 sm:mx-40 mx-4 px-3 py-3 sm:py-0 items-center sm:space-x-6 backdrop-blur-lg  my-6'>
        
          <motion.img 
                  initial={{x: 200, opacity:0}}
                  transition={{duration:1}}
                  whileInView={{opacity:1, x:0}}
          src={car} alt=""  className='rounded-md w-[200px] h-[158px]  sm:w-[500px] sm:h-[288px]  object-contain text-xl mr-2 sm:mb-2'/>
        
        <motion.div
                initial={{x: -200, opacity:0}}
                transition={{duration:1}}
                whileInView={{opacity:1, x:0}} 
        className=' flex flex-col flex-1'>
          <p className='text-blue-800 text-sm sm:text-xl font-bold mb-4'> Véhicules électriques </p>
          <p className=' text-slate-900 text-[12px] sm:text-lg text-justify'>
          Plongez déjà dans le futur avec <b className=' text-red-800 italic'>Best-Transport</b> pour avoir les véhicules électriques du futures. Des véhicules qui ont une autonomie de très longue durée adaptés à vos bésoins, nos routes et notre climat sous les tropics.
          <p className='text-blue-800 text-xs sm:text-xl font-bold mb-4 cursor-pointer'>Voir +</p>
          </p>
        </motion.div>
    </div>
  )
}

export default Electro