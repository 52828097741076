import React from "react";
import acor from "../img/acor.jpg";
import consult from "../img/consult.jpg";
import { motion } from "framer-motion";

function Transport() {
	return (
		<div className="flex flex-1 flex-col sm:mx-40  px-3 mt-6 sm:space-x-6 text-slate-900">
			<p className=" bg-red-900 h-8 flex-1 -skew-x-[40deg] text-white Nunito font-bold px-3 text-lg sm:text-2xl shadow-md shadow-lime-50 mb-4">
				Best-Transport Offre{" "}
			</p>

			<div className="flex flex-col flex-1 text-[16px]  pt-4 backdrop-blur-md">
				<div className="flex my-4   text-orange-600  text-xl  font-bold  items-center  bg-white rounded-lg p-2 text-center  space-x-4">
					<p className=" animate-bounce">Info !</p>
					<p className=" text-neutral-900">Best-Transport élargit ses horizons</p>
				</div>

				
				<div className="hidden sm:flex items-center space-x-6 mb-6">
					<motion.p
						initial={{ opacity: 0, x: -100 }}
						transition={{ duration: 1.2 }}
						whileInView={{ opacity: 1, x: 0 }}
						viewport={{ once: true }}
						className=" flex  flex-1  ">
						<img src={consult} alt="" className="rounded-md" />
					</motion.p>

					<motion.p
						initial={{ opacity: 0, x: 100 }}
						transition={{ duration: 1.2 }}
						whileInView={{ opacity: 1, x: 0 }}
						viewport={{ once: true }}
						className=" flex flex-col  flex-1 space-y-4 ">
						<p className=" text-sky-700 text-lg font-bold">
						Séance de travail d'affaire avec le Consule Honoraire de la 
Guinée Equatoriale au Mali
 
						</p>
						<p>
							Le Mercredi 14 Août 2024 à nos bureaux à Abidjan, Marcory, Zone 4, le Directeur Général de Best-Transport <b>M. CISSE MOHAMED</b> a
							eu une séance de travail de Monsieur avec{" "}
							<b> son Excellence Mr KANE Souleymane, Consule Honoraire de la 
							Guinée Equatoriale au Mali</b>. <br />
							Cette rencontre a porté sur les modalités d'un partenairiat en vue du développement de l'industrie automobiles en Afrique. <br />
							La rencontre s'est déroullé dans un atmosphère très fraternel et convivial. Les deux parties ont convénue de se revoir voir pour mettre en oeuvre les axes de ce partenariat.
						</p>
					</motion.p>
				</div>

				<div className="sm:hidden flex-col w-full   mb-6">
					<motion.p
						initial={{ opacity: 0, x: -100 }}
						transition={{ duration: 1.2 }}
						whileInView={{ opacity: 1, x: 0 }}
						viewport={{ once: true }}
						className=" flex  flex-1  ">
						<img src={consult} alt="" className="rounded-md" />
					</motion.p>

					<motion.p
						initial={{ opacity: 0, x: 100 }}
						transition={{ duration: 1.2 }}
						whileInView={{ opacity: 1, x: 0 }}
						viewport={{ once: true }}
						className=" flex flex-col  flex-1 space-y-4 ">
						<p className=" text-sky-700 text-lg font-bold text-justify">
						Séance de travail d'affaire avec le Consule Honoraire de la 
						Guinée Equatoriale au Mali
						</p>
						<p className="">
						Le Mercredi 14 Août 2024 à nos bureaux à Abidjan, Marcory, Zone 4, le Directeur Général de Best-Transport <b>M. CISSE MOHAMED</b> a
							eu une séance de travail de Monsieur avec{" "}
							<b> son Excellence Mr KANE Souleymane, Consule Honoraire de la 
							Guinée Equatoriale au Mali</b>. <br />
							Cette rencontre a porté sur les modalités d'un partenairiat en vue du développement de l'industrie automobiles en Afrique. <br />
							La rencontre s'est déroullé dans un atmosphère très fraternel et convivial. Les deux parties ont convénue de se revoir voir pour mettre en oeuvre les axes de ce partenariat.
						</p>
					</motion.p>
				</div>



				<div className="hidden sm:flex items-center space-x-6 mb-6">
					<motion.p
						initial={{ opacity: 0, x: -100 }}
						transition={{ duration: 1.2 }}
						whileInView={{ opacity: 1, x: 0 }}
						viewport={{ once: true }}
						className=" flex  flex-1  ">
						<img src={acor} alt="" className="rounded-md" />
					</motion.p>

					<motion.p
						initial={{ opacity: 0, x: 100 }}
						transition={{ duration: 1.2 }}
						whileInView={{ opacity: 1, x: 0 }}
						viewport={{ once: true }}
						className=" flex flex-col  flex-1 space-y-4 ">
						<p className=" text-sky-700 text-lg font-bold">
							Signature de parténariat avec CFA-MOTOR
						</p>
						<p>
							Le Directeur Général de Best-Transport <b>M. CISSE MOHAMED</b> a
							signe un accord de partenariat avec{" "}
							<b> CFAO Motors - Toyota Côte d'Ivoire</b>. <br />
							Cet accors a porté sur la promotion du véhicule de marque Toyota
							Vitz vendu par le promoteur. <br />
							Conçue pour la ville, la Toyota Vitz se faufile partout grâce à sa
							grande agilité et sa maniabilité. Bien que compacte, elle surprend
							par son espace disponible. Avec en plus une consommation de
							carburant optimisée, elle est la citadine par excellence et vous
							fera aimer la conduite en milieu urbain.
						</p>
					</motion.p>
				</div>

				<div className="sm:hidden flex-col w-full   mb-6">
					<motion.p
						initial={{ opacity: 0, x: -100 }}
						transition={{ duration: 1.2 }}
						whileInView={{ opacity: 1, x: 0 }}
						viewport={{ once: true }}
						className=" flex  flex-1  ">
						<img src={acor} alt="" className="rounded-md" />
					</motion.p>

					<motion.p
						initial={{ opacity: 0, x: 100 }}
						transition={{ duration: 1.2 }}
						whileInView={{ opacity: 1, x: 0 }}
						viewport={{ once: true }}
						className=" flex flex-col  flex-1 space-y-4 ">
						<p className=" text-sky-700 text-lg font-bold text-justify">
							Signature de parténariat avec CFA-MOTOR
						</p>
						<p className="">
							Le Directeur Général de Best-Transport <b>M. CISSE MOHAMED</b> a
							signe un accord de partenariat avec{" "}
							<b> CFAO Motors - Toyota Côte d'Ivoire</b>. <br />
							Cet accors a porté sur la promotion du véhicule de marque Toyota
							Vitz vendu par le promoteur. <br />
							Conçue pour la ville, la Toyota Vitz se faufile partout grâce à sa
							grande agilité et sa maniabilité. Bien que compacte, elle surprend
							par son espace disponible. Avec en plus une consommation de
							carburant optimisée, elle est la citadine par excellence et vous
							fera aimer la conduite en milieu urbain.
						</p>
					</motion.p>
				</div>
			</div>

			<motion.p
				initial={{ opacity: 0, y: 50 }}
				transition={{ duration: 1.2 }}
				whileInView={{ opacity: 1, x: 0 }}
				viewport={{ once: true }}
				className=" flex flex-col flex-1 text-[16px] mb-8 text-justify">
				Best-Transport est un importateur et un distributeur de véhicules toute
				marque, de pièces détachées pour voitures, camionnettes et motocyclettes
				en pleine croissance. <br />
				Nous sommes une plate-forme de vente et de logistique en mode
				"just-in-time" à des partenaires. Nous dirigeons nos services
				principalement vers les ateliers et les magasins de voitures. <br />
				Notre offre comprend des pièces de rechange de systèmes automobiles, des
				pièces et accessoires de moto, des filtres, des huiles, des produits
				chimiques pour automobiles ou des accessoires automobiles.
			</motion.p>

			{/*<div className='grid lg:grid-cols-3 gap-6 my-4 '>
          
       { <motion.img 
         initial={{opacity:0, y:-100,}}
         transition={{duration:1.2,}}
         whileInView={{opacity:1, y:0, }}
         viewport={{once: true}}
        src={piece} alt=""  className=' w-[200px] h-[188px]    object-contain  '/>}
      
      <p className='flex items-center'>
        <motion.img 
         initial={{opacity:0, y:-100,}}
         transition={{duration:1.2,}}
         whileInView={{opacity:1, y:0, }}
         viewport={{once: true}}
        src={trans} alt=""  className=' w-[200px] h-[188px]    object-contain  '/>
          <p className='flex flex-col items-center'>
          <motion.img 
         initial={{opacity:0, y:-100,}}
         transition={{duration:1.2,}}
         whileInView={{opacity:1, y:0, }}
         viewport={{once: true}}
        src={app} alt=""  className=' w-[100px] h-[88px]   object-contain  '/>
        <p className='flex justify-center font-bold text-violet-900 '>Notre App <br /> Bientôt !!!</p>
          </p>
        </p>

           <motion.img 
         initial={{opacity:0, y:-100,}}
         transition={{duration:1.2,}}
         whileInView={{opacity:1, y:0, }}
         viewport={{once: true}}
        src={flotte} alt=""  className=' w-[200px] h-[188px]   object-contain  '/>
       </div>*/}
		</div>
	);
}

export default Transport;
