import React, { useState, useEffect } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import logo from "../img/logog.png";
import eng from "../img/eng.png";
import tel from "../img/telephon.png";
import { Link } from "react-router-dom";

function Header() {
	const [nav, setNav] = useState(false);
	const [color, setColor] = useState("transparent");
	const [textColor, setTextColor] = useState("#f1faee");

	const handleNav = () => {
		setNav(!nav);
	};

	useEffect(() => {
		const changeColor = () => {
			if (window.scrollY >= 90) {
				setColor("#ffffff");
				setTextColor("#000000 ");
			} else {
				setColor("transparent");
				setTextColor("#f1faee");
			}
		};
		window.addEventListener("scroll", changeColor);
	}, []);
	return (
		<div
			style={{ backgroundColor: `${color}` }}
			className="fixed left-0 top-0 w-screen  z-[50] ease-in duration-300  text-white font-thin px-8 py-2 items-center  flex justify-between ">
			<div
				style={{ backgroundColor: `${color}` }}
				className="flex justify-between items-center w-full ease-in duration-300 ">
				<Link to="/">
					<img
						src={logo}
						alt=""
						className=" w-24  sm:w-36 rounded-full object-fill "
					/>
				</Link>

				<div className="flex flex-col">
					<div className="flex justify-between items-center ">
						<p className="hidden sm:flex Ephesis text-base sm:text-4xl text-red-600 italic font-bold cursor-pointer">
							Best-Transport
						</p>

						<p className=" hidden sm:flex Nunito text-2xl text-blue-800 italic font-medium">
							Vehicle World Solution
						</p>

						<p className="flex items-center text-red-800 italic">
							{" "}
							<img src={tel} alt="" className=" w-4 sm:w-7 mr-2" />{" "}
							<p className="text-xs font-bold mr-5 sm:mr-0 ">
								+225 07 48 36 41 68
							</p>
						</p>
					</div>
					<ul
						style={{ color: `${textColor}` }}
						className="hidden sm:flex space-x-10 Roboto font-bold">
						<li className=" cursor-pointer py-1 duration-300 relative  text-xl after:absolute after:bottom-0 after:right-full after:bg-[#bf6b0a] after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-[#bf6b0a]">
							<Link to="/">Accueil</Link>
						</li>

						<li className="cursor-pointer py-1 duration-300 relative  text-xl after:absolute after:bottom-0 after:right-full after:bg-[#bf6b0a] after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-[#bf6b0a]">
							<Link to="/vehicules">Véhicules</Link>
						</li>

						<li className="cursor-pointer py-1 duration-300 relative  text-xl after:absolute after:bottom-0 after:right-full after:bg-[#bf6b0a] after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-[#bf6b0a]">
							<Link to="/etechnologie">E-Technologie</Link>
						</li>

						<li className="cursor-pointer py-1 duration-300 relative  text-xl after:absolute after:bottom-0 after:right-full after:bg-[#bf6b0a] after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-[#bf6b0a]">
							<Link to="/piecesdetache">Pièces Détachées</Link>
						</li>

						<li className="cursor-pointer py-1 duration-300 relative text-xl after:absolute after:bottom-0 after:right-full after:bg-[#bf6b0a] after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-[#bf6b0a]">
							<Link to="/accessoires">Accessoires</Link>
						</li>
						<li className="cursor-pointer py-1 duration-300 relative text-xl after:absolute after:bottom-0 after:right-full after:bg-[#bf6b0a] after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-[#bf6b0a]">
							<Link to="/contact">Contactez-nous</Link>
						</li>
					</ul>
				</div>
			</div>

			{/*Mobile button*/}
			<div onClick={handleNav} className="block sm:hidden z-10 ">
				{nav ? (
					<AiOutlineClose size={20} style={{ color: `${textColor}` }} />
				) : (
					<AiOutlineMenu size={20} style={{ color: `${textColor}` }} />
				)}
			</div>
			<div
				className={
					nav
						? "sm:hidden absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center w-full h-screen bg-black text-center ease-in duration-300"
						: "sm:hidden absolute top-0 left-[-100%] right-0 bottom-0 flex justify-center items-center w-full h-screen bg-black text-center ease-in duration-300"
				}>
				<ul className="flex flex-col items-center justify-center space-y-2">
					<li className=" py-1 duration-300 relative font-thin text-xl after:absolute after:bottom-0 after:right-full after:bg-white after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-white">
						<Link to="/" onClick={handleNav}>
							<img src={eng} alt="" className="w-3 h-3 mr-2" />
							<p className="">Eng</p>
						</Link>
					</li>
					<li className=" py-1 duration-300 relative font-thin text-xl after:absolute after:bottom-0 after:right-full after:bg-white after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-white">
						<Link to="/" onClick={handleNav}>
							Accueil
						</Link>
					</li>
					<li className=" py-1 duration-300 relative font-thin text-xl after:absolute after:bottom-0 after:right-full after:bg-white after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-white">
						<Link to="/vehicules" onClick={handleNav}>
							Véhicules
						</Link>
					</li>

					<li className=" py-1 duration-300 relative font-thin text-xl after:absolute after:bottom-0 after:right-full after:bg-white after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-white">
						<Link to="/etechnologie" onClick={handleNav}>
							E-Technologie
						</Link>
					</li>

					<li className=" py-1 duration-300 relative font-thin text-xl after:absolute after:bottom-0 after:right-full after:bg-white after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-white">
						<Link to="/piecesdetache" onClick={handleNav}>
							Pièces Détachées
						</Link>
					</li>
					<li className="cursor-pointer py-1 duration-300 relative text-xl after:absolute after:bottom-0 after:right-full after:bg-[#bf6b0a] after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-[#bf6b0a]">
						<Link to="/accessoires" onClick={handleNav}>
							Accessoires
						</Link>
					</li>

					<li className=" py-1 duration-300 relative font-thin text-xl after:absolute after:bottom-0 after:right-full after:bg-white after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-white">
						<Link to="/contact" onClick={handleNav}>
							Contactez-nous
						</Link>
					</li>

					<li></li>
				</ul>
			</div>
		</div>
	);
}

export default Header;
