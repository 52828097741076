const sliderData = [
  {
    image: "image/car1.png",
    heading: "Slide One",
    title:"Véhicule utilitaires",
    topic:"SUV",
    seeLink:"",
    subLink:"",
    desc: "Nous avons des SUV de toute marque, de qualité et d'origine avec garanti disponible pour toutes les bourses ",
  },

  {
    image: "image/car2.png",
    heading: "Slide One",
    title:"TRANSPORT DE BIENS",
    topic:"MARCHANDISES",
    seeLink:"",
    subLink:"",
    desc: "Le transport de biens et marchandises vous impose d'avoir de véhicules robustes. Vous trouvez ces véhicules de toute marque avec nous",
  },

  {
    image: "image/car4.png",
    heading: "Slide One",
    title:"Pick-Up",
    topic:"D'ORIGINE",
    seeLink:"",
    subLink:"",
    desc: "Nous metons à votre disposition des véhicules de types 4X4 et Pick-Up, robustes et adaptés pour nos routes. ",
  },

  {
    image: "image/car3.png",
    heading: "Slide One",
    title:"TRANSPORT DE",
    topic:"PERSONNEL",
    seeLink:"",
    subLink:"",
    desc: "Parce que votre personnel est votre force, nous metons à votre disposition des Cars et des mini-cars de toute marque.",
  },
  
];


const etechData = [
  {
    image: "image/car1.png",
    heading: "Slide One",
    title:"Véhicule utilitaires",
    topic:"SUV",
    seeLink:"",
    subLink:"",
    desc: "Nous avons des SUV de toute marque, de qualité et d'origine avec garanti disponible pour toutes les bourses ",
  },

  {
    image: "image/car2.png",
    heading: "Slide One",
    title:"TRANSPORT DE BIENS",
    topic:"MARCHANDISES",
    seeLink:"",
    subLink:"",
    desc: "Le transport de biens et marchandises vous impose d'avoir de véhicules robustes. Vous trouvez ces véhicules de toute marque avec nous",
  },

  {
    image: "image/car4.png",
    heading: "Slide One",
    title:"Pick-Up",
    topic:"D'ORIGINE",
    seeLink:"",
    subLink:"",
    desc: "Nous metons à votre disposition des véhicules de types 4X4 et Pick-Up, robustes et adaptés pour nos routes. ",
  },

  {
    image: "image/car3.png",
    heading: "Slide One",
    title:"TRANSPORT DE",
    topic:"PERSONNEL",
    seeLink:"",
    subLink:"",
    desc: "Parce que votre personnel est votre force, nous metons à votre disposition des Cars et des mini-cars de toute marque.",
  },
  
];


const pieceData = [
  {
    image: "image/car1.png",
    heading: "Slide One",
    title:"Véhicule utilitaires",
    topic:"SUV",
    seeLink:"",
    subLink:"",
    desc: "Nous avons des SUV de toute marque, de qualité et d'origine avec garanti disponible pour toutes les bourses ",
  },

  {
    image: "image/car2.png",
    heading: "Slide One",
    title:"TRANSPORT DE BIENS",
    topic:"MARCHANDISES",
    seeLink:"",
    subLink:"",
    desc: "Le transport de biens et marchandises vous impose d'avoir de véhicules robustes. Vous trouvez ces véhicules de toute marque avec nous",
  },

  {
    image: "image/car4.png",
    heading: "Slide One",
    title:"Pick-Up",
    topic:"D'ORIGINE",
    seeLink:"",
    subLink:"",
    desc: "Nous metons à votre disposition des véhicules de types 4X4 et Pick-Up, robustes et adaptés pour nos routes. ",
  },

  {
    image: "image/car3.png",
    heading: "Slide One",
    title:"TRANSPORT DE",
    topic:"PERSONNEL",
    seeLink:"",
    subLink:"",
    desc: "Parce que votre personnel est votre force, nous metons à votre disposition des Cars et des mini-cars de toute marque.",
  },
  
];

const accessoireData = [
  {
    image: "image/car1.png",
    heading: "Slide One",
    title:"Véhicule utilitaires",
    topic:"SUV",
    seeLink:"",
    subLink:"",
    desc: "Nous avons des SUV de toute marque, de qualité et d'origine avec garanti disponible pour toutes les bourses ",
  },

  {
    image: "image/car2.png",
    heading: "Slide One",
    title:"TRANSPORT DE BIENS",
    topic:"MARCHANDISES",
    seeLink:"",
    subLink:"",
    desc: "Le transport de biens et marchandises vous impose d'avoir de véhicules robustes. Vous trouvez ces véhicules de toute marque avec nous",
  },

  {
    image: "image/car4.png",
    heading: "Slide One",
    title:"Pick-Up",
    topic:"D'ORIGINE",
    seeLink:"",
    subLink:"",
    desc: "Nous metons à votre disposition des véhicules de types 4X4 et Pick-Up, robustes et adaptés pour nos routes. ",
  },

  {
    image: "image/car3.png",
    heading: "Slide One",
    title:"TRANSPORT DE",
    topic:"PERSONNEL",
    seeLink:"",
    subLink:"",
    desc: "Parce que votre personnel est votre force, nous metons à votre disposition des Cars et des mini-cars de toute marque.",
  },
  
];

export {sliderData, etechData, pieceData, accessoireData}