import React, { useRef } from 'react';
import { motion } from "framer-motion"
import { useState } from 'react'
import emailjs from '@emailjs/browser'
import { Toaster, toast } from "react-hot-toast";


function Contact() {

  const [name, setName] = useState("")
  const [firstName, setFirstName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")

  
  


  const handleSubmit = (e) => {
    e.preventDefault()
    //keys and services
    const templateId ='template_2m8bpjv'
    const publicKey = 'vRKpOxPaAllPvKD00'
    const serviceId = 'service_d6yabjl' 

    //create new object

    const templateParams ={
      to_name:'Scoops Together',
      from_name:name,
      from_firstName:firstName,
      from_email:email,
      from_phone:phone,
      subject:subject,
      message:message,
    }
    
    //send theEmail
    emailjs.send(serviceId, templateId, templateParams, publicKey)
    .then((response)=>{
      console.log('Email envoyé avec succès', response)
      setEmail('')
      setFirstName('')
      setMessage('')
      setPhone('')
      setName('')
      setSubject('')
    })
    .catch((error)=>{
      console.error('Error sending', error)
    })

    //////////
    const refreshToast = toast.loading("Votre message a été envoyé! notre équipe vous contactera.");
			toast.success("Votre message a été envoyé! notre équipe vous contactera", {
				id: refreshToast,
        duration: 4000,
			});

      /// navigate to
  }


  return (
    <motion.div
    initial={{y:-100, opacity:0, }}
    transition={{duration:1.2}}
    whileInView={{opacity:1, y:0}}
    viewport={{once: true}}
    className='flex flex-col px-6 sm:px-40 items-center justify-center bg-transparent'
    >

      <p className='flex justify-center text-lg  sm:text-3xl py-4 text-[#c2742b] font-semibold mt-11 '>
       Contactez-nous
      </p>
      <Toaster />
      <div className='flex-1 items-center'>
            <form className=''>

              <div className='flex space-x-2'>
                <p className='flex-1'>
                  <input 
                  type="text" 
                  placeholder="Votre nom"
                  className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none rounded-md"
                  />
                </p>

                <p className='flex-1'>
                  <input 
                  type="text" 
                  placeholder="Votre Prénoms"
                  className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none rounded-md"
                  />
                </p>

              </div>

              <div className='flex space-x-2'>
                <p className='flex-1'>
                  <input 
                  type="email" 
                  placeholder="Votre email"
                  className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none rounded-md"
                  />
                </p>

                <p className='flex-1'>
                  <input 
                  type="text" 
                  placeholder="Votre Numéro cell.."
                  className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none rounded-md"
                  />
                </p>
                
            </div>

            <div className='flex space-x-2'>
                <p className='flex-1'>
                  <input 
                  type="text" 
                  placeholder="Objet de votre message"
                  className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none rounded-md"
                  />
                </p>

            
                
              </div>
            

              <textarea name="" id="" cols="30" rows="10"  className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none rounded-md" placeholder='Votre message'>

              </textarea>
              <button className="w-full m-2 p-2 rounded-md bg-cyan-500 hover:bg-cyan-600 text-2xl text-white" >
                Valider ma demande
              </button>
            </form>

          </div>

    </motion.div>
  )
}

export default Contact