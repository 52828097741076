import React, { useEffect } from 'react'
import { GiPositionMarker } from "react-icons/gi"
import { MdOutlineAlternateEmail } from "react-icons/md"
import AOS from 'aos';
import 'aos/dist/aos.css';
import { MdLocalPhone } from "react-icons/md";
import logocar from "../img/logocar.png"
import { motion } from 'framer-motion';

function Footer() {

  const transition = {type: 'spring', duration:3}
  const mobile = window.innerWidth <= 768 ? true : false
  
  useEffect(() => {
  
  }, []);

  return (
      <div className='flex flex-1 flex-col sm:px-40 justify-center text-white pt-5 pb-4 bg-indigo-950'>
      <div className='flex flex-1 justify-center text-2xl items-center my-4'>
        <p
          className=' z-10 text-xl italic uppercase'>Best-Transport</p>
        <p className='z-20 '>
          <motion.img
           initial={{x: -200, opacity:0}}
           transition={{duration:1}}
           whileInView={{opacity:1, x:0}}
           
            src={logocar} alt="" className='h-6 ' />
        </p>
          </div>
          <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-2 justify-between text-[15px]'>
                <div>
                  <p className=' flex  items-center'> <GiPositionMarker className='mr-2'/> Siège Afrique: Bouake Gare Routière,</p>
                  
                  <p className=' flex  items-center'> <MdOutlineAlternateEmail className='mr-2'/> Email: info.besttransport@gmail.com</p>
                  <p className=' flex  items-center'> <MdLocalPhone className='mr-2'/> Cell: +225 07 48 36 41 68</p>
              </div>
              
              <div></div>
              
                <div>
                  <p className=' flex  items-center'> <GiPositionMarker className='mr-2'/> Siège Europe: 189 South wark Park Road,<br /> London SE16 3TX</p>
              
                  <p className=' flex  items-center'> <MdLocalPhone className='mr-2'/> Cell: +44 79 6077 5370</p>
                </div>
          </div>
    </div>
  )
}

export default Footer