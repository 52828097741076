import React from "react";
import VehiculeUnit from "./VehiculeUnit";
import Fam from "../../img/suv4.jpg";
import Fam1 from "../../img/transp.png";
import Fam2 from "../../img/truck1.jpg";
import Fam3 from "../../img/car.png";

function Vehicules() {
	return (
		<div className="flex flex-1 flex-col sm:mx-40  px-3 my-6 sm:space-x-6 text-lime-50">
			<p className=" flex  space-x-4 items-center bg-red-900 h-8 flex-1 -skew-x-[40deg] text-white Nunito font-bold px-3 text-lg sm:text-2xl shadow-md shadow-lime-50 mb-4">
				<p>Best-Transport </p> <p>Véhicules</p>
			</p>
			<p className=" text-stone-950 text-lg mb-4">
				Vous trouverez chez nous des véhicules de toute marque, d'origine et
				garanti pour vos besoins et déplacements en toute sécurité.{" "}
			</p>

			<div className="flex flex-1 flex-col">
				<div className="flex flex-1 flex-col">
					<p className="font-bold text-2xl text-orange-700 my-6">
						Véhicules familliales
					</p>
					<div className=" flex w-full overflow-x-auto  scrollbar-hide space-x-4">
						<VehiculeUnit imgSrc={Fam} nom="Nom du Vehicule" />
						<VehiculeUnit imgSrc={Fam} nom="Nom du Vehicule" />
						<VehiculeUnit imgSrc={Fam} nom="Nom du Vehicule" />
					</div>
				</div>

				<div className="flex flex-1 flex-col">
					<p className="font-bold text-2xl text-orange-700 my-6">
						Véhicules SUV
					</p>
					<div className=" flex w-full overflow-x-auto  scrollbar-hide space-x-4">
						<VehiculeUnit imgSrc={Fam1} nom="Nom du Vehicule" />
						<VehiculeUnit imgSrc={Fam1} nom="Nom du Vehicule" />
						<VehiculeUnit imgSrc={Fam1} nom="Nom du Vehicule" />
						<VehiculeUnit imgSrc={Fam1} nom="Nom du Vehicule" />
					</div>
				</div>

				<div className="flex flex-1 flex-col">
					<p className="font-bold text-2xl text-orange-700 my-6">
						Véhicules Camions
					</p>
					<div className=" flex w-full overflow-x-auto  scrollbar-hide space-x-4">
						<VehiculeUnit imgSrc={Fam2} nom="Nom du Vehicule" />

						<VehiculeUnit imgSrc={Fam2} nom="Nom du Vehicule" />
					</div>
				</div>

				<div className="flex flex-1 flex-col">
					<p className="font-bold text-2xl text-orange-700 my-6">
						Véhicules Cars
					</p>
					<div className=" flex w-full overflow-x-auto  scrollbar-hide space-x-4">
						<VehiculeUnit imgSrc={Fam3} nom="Nom du Vehicule" />
						<VehiculeUnit imgSrc={Fam3} nom="Nom du Vehicule" />
					</div>
				</div>

				<div className="flex flex-1 flex-col">
					<p className="font-bold text-2xl text-orange-700 my-6">
						Véhicules de LUXE
					</p>
					<div className=" flex w-full overflow-x-auto  scrollbar-hide space-x-4">
						<VehiculeUnit imgSrc={Fam3} nom="Nom du Vehicule" />
						<VehiculeUnit imgSrc={Fam3} nom="Nom du Vehicule" />
					</div>
				</div>
			</div>
		</div>
	);
}

export default Vehicules;
