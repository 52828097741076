import React from "react";
import trans from "../img/transpo.png";
import piece from "../img/voiture.png";
import app from "../img/app.png";
import flotte from "../img/flotte.png";

import { motion } from "framer-motion";

function Ourcars() {
	return (
		<div className="flex flex-1 flex-col sm:mx-40  px-3 mt-6 sm:space-x-6 text-slate-900">
			<div className="flex justify-between my-4 ">
				<motion.img
					initial={{ opacity: 0, y: -100 }}
					transition={{ duration: 1.2 }}
					whileInView={{ opacity: 1, y: 0 }}
					viewport={{ once: true }}
					src={piece}
					alt=""
					className=" w-[200px] h-[188px] hidden sm:inline-block   object-contain  "
				/>

				<p className="flex items-center">
					<motion.img
						initial={{ opacity: 0, y: -100 }}
						transition={{ duration: 1.2 }}
						whileInView={{ opacity: 1, y: 0 }}
						viewport={{ once: true }}
						src={trans}
						alt=""
						className=" w-[100px] sm:w-[200px] h-[188px]    object-contain  "
					/>
					<p className="flex flex-col items-center">
						<motion.img
							initial={{ opacity: 0, y: -100 }}
							transition={{ duration: 1.2 }}
							whileInView={{ opacity: 1, y: 0 }}
							viewport={{ once: true }}
							src={app}
							alt=""
							className=" w-[100px] h-[88px]   object-contain  "
						/>
						<p className="flex justify-center font-bold text-violet-900 ">
							Notre App <br /> Bientôt !!!
						</p>
					</p>
				</p>

				<motion.img
					initial={{ opacity: 0, y: -100 }}
					transition={{ duration: 1.2 }}
					whileInView={{ opacity: 1, y: 0 }}
					viewport={{ once: true }}
					src={flotte}
					alt=""
					className=" w-[200px] h-[188px]   object-contain  "
				/>
			</div>
		</div>
	);
}

export default Ourcars;
