import React from 'react'
import MySliderAppAccessoires from '../../components/MySliderAppAccessoires'


function Accessoires() {
  return (
    <div className='justify-center  '>
       <MySliderAppAccessoires />
       
    </div>
  )
}

export default Accessoires