import React from 'react'
import piece from '../../img/voiture.png'
import {motion} from "framer-motion"

function PieceDetaches() {
  return (
    <div className='flex flex-1 flex-col sm:mx-40  px-3 mt-6 sm:space-x-6 text-slate-900'>

        <p className=' bg-red-900 h-8 flex-1 -skew-x-[40deg] text-white Nunito font-bold px-3 text-lg sm:text-2xl shadow-md shadow-lime-50 mb-4'>Disponible actuellement </p> 
        <div className='grid lg:grid-cols-3 gap-6 text-[16px]  pt-4 backdrop-blur-md'>
      
        
          <motion.ul
           initial={{opacity:0, y:-100,}}
           transition={{duration:1.2,}}
           whileInView={{opacity:1, y:0, }}
           viewport={{once: true}}
           className='flex flex-col flex-1 ml-4'>
            <li className=' list-disc'>Système de suspension et de direction</li>
            <li className=' list-disc'>Système de freinage</li>
            <li className=' list-disc'>Gamme de motos</li>
            <li className=' list-disc'>Equipement des ateliers</li>
            <li className=' list-disc'>Amortisseurs et ressorts</li>
            <li className=' list-disc'>Transmission</li>
            <li className=' list-disc'>Filtres</li>
            
           

          </motion.ul>

          <motion.ul
           initial={{opacity:0, y:100,}}
           transition={{duration:1.2,}}
           whileInView={{opacity:1, y:0, }}
           viewport={{once: true}} 
          className='flex flex-col flex-1 '>
            
            <li className=' list-disc'>Distribution</li>
            <li className=' list-disc'>Moteurs, joints et accessoires</li>
            <li className=' list-disc'>Câblage</li>
            <li className=' list-disc'>Système de refroidissement, climatisation</li>
            <li className=' list-disc'>Lignes, fils, bandes</li>
            <li className=' list-disc'>Huiles et produits chimiques</li>
            



          </motion.ul>


          <motion.ul
           initial={{opacity:0, y:100,}}
           transition={{duration:1.2,}}
           whileInView={{opacity:1, y:0, }}
           viewport={{once: true}} 
          className='flex flex-col flex-1 '>
            <li className=' list-disc'>Système d'échappement</li>
            <li className=' list-disc'>Carrosserie</li>
            <li className=' list-disc'>Batteries</li>
            <li className=' list-disc'>Éclairage</li>
            <li className=' list-disc'>Les pneus et les jantes</li>
            <li className=' list-disc'>Accessoires et fonctionnement</li>
            <li className=' list-disc'>Essuie-glaces et monte-charges</li>


          </motion.ul>

          

        </div>

        <div className='flex justify-between sm:-mt-28'>
          <p></p>
          <motion.img
         initial={{opacity:0, x:100,}}
         transition={{duration:1.2,}}
         whileInView={{opacity:1, x:0, }}
         viewport={{once: true}}
         src={piece} alt=""  className='rounded-md w-[200px] h-[188px]  sm:w-[900px] sm:h-[488px]  object-contain text-xl z-10 mx-auto'/>
        </div>
        
    </div>
  )
}

export default PieceDetaches