import React from 'react'
import MySliderAppEtech from '../../components/MySliderAppEtech'
import Etech from './Etech'

function Etechnologie() {
  return (
    <div className='justify-center  '>
      <MySliderAppEtech />
        <Etech />
    </div>
  )
}

export default Etechnologie