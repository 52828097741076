import React from 'react'
import { motion } from 'framer-motion'

function Hero() {
  return (
    <div className='w-full mx-auto top-0'>
      {/*<div className="overlay"></div>*/}
      <div className="content  ">
      <motion.h1 
      initial={{x: -200, opacity:0}}
      transition={{duration:1.2}}
      whileInView={{opacity:1, x:0}}
      className='  absolute text-orange-600  text-2xl sm:text-5xl top-[20%] left-[5px]  sm:left-[10px] z-[4] font-bold '>Avec Best-Transport</motion.h1>
          <h1 className=' absolute text-white  text-xl sm:text-5xl top-[35%]  z-[4] font-bold left-[5px] sm:left-[10px] md:flex-nowrap '>Profiter </h1>
          <p className='title absolute text-white  text-lg sm:text-2xl top-[45%]  z-[4]  left-[5px]  sm:left-[10px] '>dès maintenant</p>
          <h1 className='topic absolute text-orange-600 text-xl sm:text-5xl top-[55%]  z-[4] font-bold left-[5px]  sm:left-[10px] '>du Meilleur de l'automobile</h1>
      </div>
      {/*<div className='absolute w-[1440px] h-[450px] bg-black/40 z-[2]' />*/}
      <video 
        src="/video2.mp4" 
        autoPlay loop muted 
        className='w-[1440px] h-[500px] object-cover'
      />

    
    </div>
  )
}

export default Hero