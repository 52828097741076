import React from 'react'
import banner from '../../img/banner1.jpg'

function Topcontact() {
  return (
    <div className='flex flex-1'>
        <img src={banner} alt="" />
    </div>
  )
}

export default Topcontact