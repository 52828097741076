import React from 'react'
import { Cursor, useTypewriter } from "react-simple-typewriter";

function Texte() {

    const [text, count] = useTypewriter({
        words: [

          "Le transport des biens et des personnes en tous lieux et en tous temps en Côte d’Ivoire et partout en Afrique, 24h/24h et 7j/7 ",
        
        ],
        loop: true,
        delaySpeed: 2000,
      });

  return (
     <div className='flex my-8 sm:mx-40  text-orange-600  text-xl sm:text-2xl font-bold  items-center  bg-white rounded-lg px-3 py-2 text-center'>
        {text}
        </div>
  )
}

export default Texte