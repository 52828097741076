import React from "react";
import "./MySliderCssVehicule.css";
import { useState } from "react";
import { sliderData } from "../components/slider-data";
import { useEffect } from "react";

function MySliderAppAccessoires() {
	const [currentSlide, setCurrentSlide] = useState(0);
	const slideLength = sliderData.length;

	const autoScroll = true;
	let slideInterval;
	let intervalTime = 6000;

	const nextSlide = () => {
		setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
		console.log("next");
	};

	const prevSlide = () => {
		setCurrentSlide(currentSlide === 0 ? slideLength - 1 : currentSlide - 1);
		console.log("prev");
	};

	function auto() {
		slideInterval = setInterval(nextSlide, intervalTime);
	}

	useEffect(() => {
		setCurrentSlide(0);
	}, []);

	useEffect(() => {
		if (autoScroll) {
			auto();
		}
		return () => clearInterval(slideInterval);
	}, [currentSlide]);

	return (
		<div className="background">
			{/* carousel*/}
			<div className="carousel bg-gradient-to-t from-green-800 to-lime-950">
				{/*<!-- list item -->*/}
				<div className="list">
					{sliderData.map((slide, index) => {
						return (
							<div
								className={index === currentSlide ? "slide current" : "slide"}
								key={index}>
								{index === currentSlide && (
									<div className="item">
										<img
											src={slide.image}
											alt=""
											className="sm:w-[500px] w-[200px]"
										/>
										<div className="content">
											<div className="author mt-10">{slide.author}</div>
											<div className="title">{slide.title}</div>
											<div className="topic"> {slide.topic}</div>
											<div className="des text-lg text-black bg-slate-50/60 p-2">
												{/*<!-- lorem 50 -->*/}
												{slide.desc}
											</div>
											<div className="buttons">
												<button className=" hover:bg-transparent hover:text-black text-red-500">
													voir +
												</button>
												<button className=" hover:bg-white hover:text-black">
													Abonnez-vous
												</button>
											</div>
										</div>
									</div>
								)}
							</div>
						);
					})}
				</div>

				{/*<!-- next prev -->*/}

				<div className="arrows">
					<button
						id="prev"
						onClick={prevSlide}
						className="hidden sm:flex justify-center bg-white/70">
						Prv
					</button>
					<button
						id="next"
						onClick={nextSlide}
						className="hidden sm:flex justify-center bg-white/70">
						Srv
					</button>
				</div>
				{/*<!-- time running -->*/}
				<div className="time"></div>
			</div>
		</div>
	);
}

export default MySliderAppAccessoires;
