import React from 'react'
import Footer from './Footer'
import Header from './Header'
import call from '../img/call.png'

function Layout(props) {

    const { children } = props

  return (
    <div className='justify-center'> 
      <Header />
        <main className='flex flex-col justify-center '>
              {children}
        </main>
      <Footer />
    </div>
  )
}

export default Layout

