import React from 'react'
import { motion } from "framer-motion"

function EtechUnit({id, imgSrc, nom}) {
  return (
    <motion.div
			initial={{ y: -100, opacity: 0 }}
			transition={{ duration: 1.2 }}
			whileInView={{ opacity: 1, y: 0 }}
			viewport={{ once: true }}
			className=" flex drop-shadow-xl flex-col rounded-3xl items-center space-y-0 flex-shrink-0 w-72  md:w-[600px] xl:w-[700px] snap-center bg-[#FFFFFF] bg-gradient-to-tr from-white  to-darkGreen/20  md:p10 hover:opacity-100 opacity-100 cursor-pointer transition-opacity duration-200 pt-4 ">
			<img
				src={imgSrc}
				alt={nom}
				className=" md:h-0 w-60  h-56 md:w-0 xl:w-[350px] xl:h-[150px] mb-2 object-cover object-center rounded-2xl"
			/>
			<div className="text-black py-6 flex w-full flex-col">
				<p className=" Nunito text-2xl text-blue-800 font-bold py-4 text-center flex justify-center ">
					Nom du véhicule
				</p>

				<div className=" flex item-center flex-1 justify-between px-8  ">
					<p className=" bg-slate-300 text-red-700 text-xl px-4 py-3 flex justify-center cursor-pointer rounded-xl hover:bg-slate-200">Voir +</p>
					<p className=" bg-slate-300 text-blue-700 text-xl px-4 py-3 flex justify-center cursor-pointer rounded-xl hover:bg-slate-200">Commander</p>
				</div>
			</div>
		</motion.div>
  )
}

export default EtechUnit